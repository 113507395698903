@use '@styles/functions' as functions;

.scroll-container {
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  &:global(.snap-scroll) {
    scroll-snap-type: x mandatory;

    & > * > * {
      scroll-snap-align: center;
    }
  }
}

.scroll-bar-container {
  width: 100%;
  margin: 0 auto;
  background: var(--tertiary-light);
  height: 2px;
  border-radius: 3%;
  overflow: hidden;
  margin-top: functions.spacing(6);

  .scroll-bar-element {
    background: var(--primary-dark);
    height: 2px;
    position: relative;
  }
}
