@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;
@use '@styles/utilities';

.board-with-fields {
  :global(.scroll-bar-container) {
    display: none;
  }

  &-item {
    & > a {
      position: relative;
      font-size: utilities.px-to-rem(14px);
      color: var(--text-primary-dark);
      text-decoration: none;
      cursor: pointer;
    }
  }

  .board-content {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: functions.spacing(6);

    :global(.text-module) {
      background-color: var(--background-1);
      flex: auto;
      padding: functions.spacing(6);

      :global(.cta-container) {
        margin-top: auto;
        justify-content: center;

        a {
          width: 100%;
        }
      }

      &:global(.left),
      &:global(.right) {
        align-items: center;
        text-align: center;

        :global(.cta-container) {
          align-items: center;
        }
      }

      &:global(.left) {
        @include breakpoints.media-breakpoint-up(md) {
          align-items: flex-start;
          text-align: left;

          :global(.cta-container) {
            align-items: flex-start;
          }
        }
      }

      &:global(.right) {
        @include breakpoints.media-breakpoint-up(md) {
          align-items: flex-end;
          text-align: right;

          :global(.cta-container) {
            align-items: flex-end;
          }
        }
      }
    }
  }

  &-2 {
    .board-content {
      @include breakpoints.media-breakpoint-up(lg) {
        display: grid;
        gap: functions.spacing(2);
        grid-template-columns: repeat(2, 1fr);
      }

      :global(.media-wrapper) {
        padding-bottom: 105.672609%;

        @include breakpoints.media-breakpoint-up(md) {
          padding-bottom: 51.460142%;
        }

        @include breakpoints.media-breakpoint-up(lg) {
          padding-bottom: 76.079347%;
        }

        @include breakpoints.media-breakpoint-up(xl) {
          padding-bottom: 63.286713%;
        }

        @include breakpoints.media-breakpoint-up(xxl) {
          padding-bottom: 55.521472%;
        }
      }

      &-item {
        display: flex;
        flex-direction: column;
        position: relative;

        :global(.shoppable-teaser) {
          :global(.media-wrapper) {
            padding-bottom: 50%;
          }
        }
      }
    }
  }

  &-3 {
    .board-content {
      display: grid;
      gap: functions.spacing(0);
      grid-template-columns: repeat(3, utilities.px-to-rem(327px));
      padding: functions.spacing(0, 4, 0, 8);
      min-width: min-content;

      @include breakpoints.media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
        padding: 0;
      }

      :global(.media-wrapper) {
        padding-bottom: 110.696095%;

        @include breakpoints.media-breakpoint-up(md) {
          padding-bottom: 51.460142%;
        }

        @include breakpoints.media-breakpoint-up(lg) {
          padding-bottom: 114.991181%;
        }

        @include breakpoints.media-breakpoint-up(xl) {
          padding-bottom: 95.514512%;
        }

        @include breakpoints.media-breakpoint-up(xxl) {
          padding-bottom: 83.796296%;
        }
      }

      :global(.text-module) {
        @include breakpoints.media-breakpoint-up(lg) {
          padding: functions.spacing(6);
        }
      }

      &-item {
        display: flex;
        flex-direction: column;
        position: relative;

        :global(.shoppable-teaser) {
          :global(.media-wrapper) {
            padding-bottom: 50%;
          }
        }
      }
    }

    :global(.scroll-bar-container) {
      display: block;
      width: utilities.px-to-rem(163px);

      @include breakpoints.media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &-4 {
    padding: functions.spacing(0, 0, 10);

    @include breakpoints.media-breakpoint-up(lg) {
      padding: functions.spacing(0, 0, 16);
    }

    .board-content {
      display: grid;
      gap: functions.spacing(2);
      grid-template-columns: repeat(4, utilities.px-to-rem(327px));
      grid-auto-columns: utilities.px-to-rem(327px);
      grid-auto-flow: column;
      padding: functions.spacing(0, 4, 0, 8);
      min-width: min-content;

      @include breakpoints.media-breakpoint-up(lg) {
        grid-template-columns: repeat(4, utilities.px-to-rem(400px));
        grid-auto-columns: utilities.px-to-rem(400px);
        padding: functions.spacing(0, 16, 0);
      }

      :global(.media-wrapper) {
        padding-bottom: 110.696095%;

        @include breakpoints.media-breakpoint-up(md) {
          padding-bottom: 51.460142%;
        }

        @include breakpoints.media-breakpoint-up(lg) {
          padding-bottom: 88.828338%;
        }

        @include breakpoints.media-breakpoint-up(xl) {
          padding-bottom: 90.5%;
        }

        @include breakpoints.media-breakpoint-up(xxl) {
          padding-bottom: 90.5%;
        }
      }

      :global(.text-module) {
        @include breakpoints.media-breakpoint-up(lg) {
          padding: functions.spacing(6, 8, 0);
        }
      }

      &-item {
        position: relative;

        :global(.shoppable-teaser) {
          :global(.media-wrapper) {
            padding-bottom: 50%;
          }
        }
      }
    }

    :global(.scroll-bar-container) {
      display: block;
      width: utilities.px-to-rem(163px);
    }
  }

  &-on {
    .board-content-item {
      position: relative;
    }

    :global(.text-module) {
      position: absolute;
      bottom: functions.spacing(8);
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
